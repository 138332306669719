import en_master_goods from "../../page/master/goods/language/en_master_goods"

const common = {
	action: "action",
	add: "add",
	address: "address",
	back: "back",
	batch: "tag",
	category: "category",
	chose_img: "chose image",
	cancel: "cancel",
	company: "company",
	courier: "courier",
	customer: "customer",
	date: "date",
	date_range: "date range",
	date_update: "updated date",
	delete: "delete",
	discount: "discount",
	difference: "selisih",
	edit: "edit",
	email: "E-mail",
	export_excel: "eksport to excel",
	export_csv: "eksport to CSV",
	export_pdf: "eksport to PDF",
	goods: "goods",
	history: "history",
	image: "image",
	info: "information",
	language: "language",
	loading: "loading...",
	name: "name",
	name_full: "full name",
	no: "Number",
	note: "note",
	no_option: "no option",
	pass: "password",
	pass_conf: "confirmation password",
	pass_new: "new password",
	pass_reset: "reset password",
	pass_change: "change password",
	price: "price",
	print: "print",
	profile: "profile",
	product: "product",
	quantity: "quantity",
	reference: "reference",
	request: "request",
	save: "save data",
	search: "search",
	serial_num: "serial number",
	supplier: "supplier",
	status: "status",
	stock: "stock",
	telp: "phone",
	unit: "unit",
	upload: "upload",
	user: "user",
	warehouse: "warehouse",
}

const main_en_language = {
	translation: {
		common,
		crop_img: { crop: "crop", ok: "crop image" },
		menu: {
			dashboard: "dashboard",
			warehouse: "warehouse",
			whstrans: "warehouse transaction",
			master: "settings",
			sales: "sales",
			ecommerce: "E-commerce",
			ecommregister: "1Toko registration",
			ecommlist: "E-commerce List",
			purchase: "purchase",
			supplychain: "supply chain",
			supplychain_point: "distribution point",
			supplychain_request: "goods request",
			report: "report",
			rka: "RKA",
		},
		mitra: {
			additemreq: "add goods request",
			edititemreq: "edit goods request",
		},
		login: {
			title: "Login",
			password: "Password",
			forgotpass: "Forgot Password ?",
			submit: "Submit",
		},
		dashboard: {
			search: { title: "Search", placeholder: "Company ID" },
		},
		warehouse: {},
		whstrans: {},
		master_goods: en_master_goods,
		master: {
			convertionadd: "add goods convertion",
			convertionedit: "edit goods convertion",
			finance: "finance",
			goods: {
				add: "add goods",
				edit: "edit goods",
				price: "price goods",
				upload: "upload goods",
				barcode: "print barcode",
				view: "goods detail",
			},
			supplier: {
				add: "add supplier",
				edit: "edit supplier",
			},
			customer: {
				add: "add customer",
				edit: "edit customer",
			},
			courier: {
				add: "add courier",
				edit: "edit courier",
			},
			groupingadd: "add goods group",
			groupingedit: "edit goods group",
			profileadd: "add profile",
			profileedit: "edit profile",
			process: {
				"": "master process",
				add: "add process",
				edit: "edit process",
			},
			storage: {
				room: "room",
				add: "add storage",
				edit: "edit storage",
				model: "storage model",
			},
			bankacc: {
				bank: "bank",
				norek: "account number",
				namerek: "account name",
				add: "add bank account",
				edit: "edit bank account",
			},
			payment_meth: {
				"": "payment method",
				time: "time (day)",
				add: "add payment method",
				edit: "edit payment method",
			},
			po_fee: {
				other_costs: "other costs",
				local: "Local",
				import: "Import",
			},
			tnc: {
				"": "trem and condition",
			},
			user: {
				"": "user",
				add: "add user",
				edit: "edit user",
			},
			whs_setting: {
				"": "warehouse setting",
				add: "add warehouse",
				edit: "edit warehouse",
			},
		},
		spal: {
			addspal: "add contract",
			editspal: "edit contract",
			addpaymentmethod: "add payment method ( contract )",
			editpaymentmethod: "edit payment method ( contract )",
			addtime: "add load & unload time ( contract )",
			edittime: "edit load & unload time ( contract )",
			adddemurrage: "add late charge ( contract )",
			editdemurrage: "edit late charge ( contract )",
			form: {
				paymentmethod: "payment method",
				demurrage: "late charge",
				time: "load & unload time",
			},
		},
		supplychain: {
			"": "supplychain",
			add: "add distribution point",
			edit: "edit distribution point",
			addgoodsreq: "add goods request",
			editgoodsreq: "edit goods request",
			detailgoodsreq: "goods request detail",
		},
		transaction: {
			common: {
				whs: "warehouse",
				search_ref: "search reference",
				trans_id: "tranasaction ID",
				add_item: "add item",
			},
			convertion: {
				"": "convertion",
				add: "add convertion",
			},
			initstock: {
				"": "init stock",
			},
			itemin: {
				"": "item in",
			},
			iteminlist: {
				"": "item in supervision",
				detail: "detail item in",
			},
			itemout: {
				"": "item out",
			},
			itemoutlist: {
				"": "item out supervision",
				detail: "detail item out",
			},
			itemstock: {
				"": "item stock",
			},
			itemmove: {
				"": "movement of goods",
			},
			itemprocess: {
				"": "process goods",
			},
			stockopname: {
				"": "stock opname",
				add: "add stock opname",
				edit: "edit stock opname",
				detail: "stock opname detail",
			},
		},
		sales: {
			returncustomer: {
				"": "return customer",
			},
		},
		purch: {
			returnsupplier: {
				"": "return supplier",
			},
			po: {
				"": "purchase order",
				create: "add purchase order",
				edit: "edit purchase order",
				create_mitra: "add mitra purchase order",
				edit_mitra: "edit mitra purchase order",
				currency: "currency",
				additional_cost: "additional cost",
				additional_discount: "additional discount",
				price_history: "price history",
				po_detail: "purchase order detail",
			},
		},
		report: {},
		rka: {
			planning: {
				"": "planning",
				add: "create plan",
				edit: "edit plan",
			},
		},
	},
}

export default main_en_language
