import { lazy } from "react";

// route
export const hrmRoute = { path: "/hrd", name: "HRM", icon: "groups" };
export const hrmPositionRoute = { path: `${hrmRoute.path}/position`, name: "Jabatan" };
export const hrmAllowanceRoute = { path: `${hrmRoute.path}/allowance`, name: "Tunjangan" };
export const hrmDeductionRoute = { path: `${hrmRoute.path}/deduction`, name: "Potongan" };
export const hrmEmployeeRoute = { path: `${hrmRoute.path}/employee`, name: "Daftar Pegawai" };

// compomnent
const HRM = lazy(() => import("../page/hrm/HRM"));
const HRMEmployee = lazy(() => import("../page/hrm/HRMEmployee"));

export const hrmListRoutes = [{ ...hrmPositionRoute, name: hrmRoute.name }, hrmEmployeeRoute];
export const hrmTabRoutes = [hrmPositionRoute, hrmAllowanceRoute, hrmDeductionRoute];
export const hrmMutationRoutes = [hrmPositionRoute, hrmAllowanceRoute, hrmDeductionRoute, hrmEmployeeRoute]

export const hrmRoutes = [
    {
        element: <HRM />,
        children: [
            { ...hrmPositionRoute, element: <></> },
            { ...hrmAllowanceRoute, element: <></> },
            { ...hrmDeductionRoute, element: <></> },
        ],
    },
    { ...hrmEmployeeRoute, element: <HRMEmployee /> }
];
