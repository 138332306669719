import { lazy } from "react";

// routes
export const spalRoute = { path: "/spal", name: "kontrak", icon: "grading" };
export const spalListRoute = { path: `${spalRoute.path}/list`, name: "Daftar kontrak" };
export const spalListCreateRoute = { path: `${spalListRoute.path}/create`, name: "spal.addspal" };
export const spalListEditRoute = { path: `${spalListRoute.path}/edit`, name: "spal.editspal" };
export const spalMasterRoute = { path: `${spalRoute.path}/hirePayment`, name: "Cara Pembayaran" };
export const spalMasterCreateRoute = { path: `${spalMasterRoute.path}/create`, name: "spal.addpaymentmethod" };
export const spalMasterEditRoute = { path: `${spalMasterRoute.path}/edit`, name: "spal.editpaymentmethod" };
export const spalTimeUnloadandLoadRoute = { path: `${spalRoute.path}/loadingPeriod`, name: "Waktu Bongkar & Muat" };
export const spalTimeUnloadandLoadCreateRoute = { path: `${spalTimeUnloadandLoadRoute.path}/create`, name: "spal.addtime" };
export const spalTimeUnloadandLoadEditRoute = { path: `${spalTimeUnloadandLoadRoute.path}/edit`, name: "spal.edittime" };
export const spalDemurrageRoute = { path: `${spalRoute.path}/demurrage`, name: "Denda Keterlambatan" };
export const spalDemurrageCreateRoute = { path: `${spalDemurrageRoute.path}/create`, name: "spal.adddemurrage" };
export const spalDemurrageEditRoute = { path: `${spalDemurrageRoute.path}/edit`, name: "spal.editdemurrage" };

// component
const SPAL = lazy(() => import("../page/spal/SPAL"));
const SPALListForm = lazy(() => import("../page/spal/list/SPALListForm"));
const SPALMaster = lazy(() => import("../page/spal/master/SPALMaster"));
const SPALSettingForm = lazy(() => import("../page/spal/SPALSettingForm"));
const SPALList = lazy(() => import("../page/spal/list/SPALList"));
const SPALTimeUnLoadLoad = lazy(() => import("../page/spal/time_unload_load/SPALTimeUnLoadLoad"));
const SPALDemurrage = lazy(() => import("../page/spal/demurrage/SPALDemurrage"));

export const spalListRoutes = [spalListRoute, spalMasterRoute, spalTimeUnloadandLoadRoute, spalDemurrageRoute];
export const spalMutationRoute = [
    spalListCreateRoute, spalListEditRoute, spalMasterCreateRoute,
    spalMasterEditRoute, spalDemurrageCreateRoute, spalDemurrageEditRoute,
    spalTimeUnloadandLoadCreateRoute, spalTimeUnloadandLoadEditRoute
];

export const spalRoutes = {
    element: <SPAL />,
    children: [
        { ...spalListRoute, element: <SPALList /> },
        { ...spalListCreateRoute, element: <SPALListForm type="add" /> },
        { ...spalListEditRoute, element: <SPALListForm type="edit" /> },
        { ...spalDemurrageRoute, element: <SPALDemurrage /> },
        { ...spalDemurrageCreateRoute, element: <SPALSettingForm type="add" process={`demurrage`} /> },
        { ...spalDemurrageEditRoute, element: <SPALSettingForm type="edit" process={`demurrage`} /> },
        { ...spalMasterRoute, element: <SPALMaster /> },
        { ...spalMasterCreateRoute, element: <SPALSettingForm type="add" process={"paymentmethod"} /> },
        { ...spalMasterEditRoute, element: <SPALSettingForm type="edit" process={"paymentmethod"} /> },
        { ...spalTimeUnloadandLoadRoute, element: <SPALTimeUnLoadLoad /> },
        { ...spalTimeUnloadandLoadCreateRoute, element: <SPALSettingForm type="add" process={"time"} /> },
        { ...spalTimeUnloadandLoadEditRoute, element: <SPALSettingForm type="edit" process={"time"} /> },
    ],
};