import React from "react";
import ReactDOM from "react-dom/client";
import App from "./src/App";
import "./main.css";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ThemeProvider } from "@mui/material";
import THEME from "./src/utils/Theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={THEME}>
        <ProSidebarProvider>
          <App />
        </ProSidebarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);
