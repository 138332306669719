import { lazy } from "react";

// route
export const whsRoute = { path: "/warehouse", name: "menu.warehouse", icon: "warehouse" };
export const stockRoute = { path: `${whsRoute.path}/view_stock`, name: "Stok Barang", code: 'IS' };
export const stockDetailRoute = { path: `${stockRoute.path}/detail`, name: "Detail Stok Barang", code: 'IS' };
export const itemInRoute = { path: `${whsRoute.path}/view_item_in`, name: "Barang Masuk", code: 'IIN' };
export const itemOutRoute = { path: `${whsRoute.path}/view_item_out`, name: "Barang Keluar", code: 'IOUT' };
export const returnSupplierRoute = { path: `${whsRoute.path}/view_retur_supplier`, name: "Retur Pemasok", code: 'RSUP' };
export const returnCustomerRoute = { path: `${whsRoute.path}/view_retur_customer`, name: "Retur Pelanggan", code: 'RCUS' };
export const depreciationRoute = { path: `${whsRoute.path}/view_depreciation`, name: "Penyusutan", code: 'DEP' };
export const whsSerialNumRoute = { path: `${whsRoute.path}/view_serial_number`, name: "Serial Number", code: 'SN' };
export const itemMoveRoute = { path: `${whsRoute.path}/item_move_list`, name: "Perpindahan Barang", code: 'IM' };
export const itemMoveDetailRoute = { path: `${itemMoveRoute.path}/detail`, name: "Detail Perpindahan Barang", code: 'IM' };

// component 
const Warehouse = lazy(() => import("../page/warehouse/Warehouse"));
const ItemStock = lazy(() => import("../page/warehouse/item_stock/ItemStock"));
const ItemStockDetail = lazy(() => import("../page/warehouse/item_stock/detail/ItemStockDetail"));
const ItemIn = lazy(() => import("../page/warehouse/item_in/ItemIn"));
const ItemOut = lazy(() => import("../page/warehouse/item_out/ItemOut"));
const ItemMove = lazy(() => import("../page/warehouse/item_move/ItemMove"));
const ItemMoveView = lazy(() => import("../page/warehouse/item_move/view/ItemMoveView"));
const ReturnSupplier = lazy(() => import("../page/warehouse/return_supplier/ReturnSupplier"));
const ReturnCustomer = lazy(() => import("../page/warehouse/return_customer/ReturnCustomer"));
const ItemDepreciation = lazy(() => import("../page/warehouse/item_depreciation/ItemDepreciation"));
const WhsSerialNumber = lazy(() => import("../page/warehouse/serial_number/SerialNumber"));

export const warehouseTabRoutes = [
    stockRoute,
    itemInRoute,
    itemOutRoute,
    returnSupplierRoute,
    returnCustomerRoute,
    whsSerialNumRoute,
    // depreciationRoute,
    itemMoveRoute,
];

export const warehouseMutationRoutes = [
    itemMoveDetailRoute,
    stockDetailRoute
]

export const warehouseRoutes = {
    element: <Warehouse />,
    children: [
        { ...stockRoute, element: <ItemStock /> },
        { ...stockDetailRoute, element: <ItemStockDetail /> },
        { ...itemInRoute, element: <ItemIn /> },
        { ...itemOutRoute, element: <ItemOut /> },
        { ...itemMoveRoute, element: <ItemMove /> },
        { ...itemMoveDetailRoute, element: <ItemMoveView /> },
        { ...returnSupplierRoute, element: <ReturnSupplier /> },
        { ...returnCustomerRoute, element: <ReturnCustomer /> },
        { ...depreciationRoute, element: <ItemDepreciation /> },
        { ...whsSerialNumRoute, element: <WhsSerialNumber /> },
    ],
};