import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./Routes/MainRoutes";
import Loading from "./components/Loading";
import "./utils/Language/Language";
import 'number-to-text/converters/en-us'
import 'number-to-text/converters/id'

function App() {
  return (
    <div className="app">
      <Suspense fallback={<Loading />}>
        <RouterProvider router={routes} />
      </Suspense>
    </div>
  );
}

export default App;
