import { lazy } from "react";

// route
export const supplyChainRoute = { path: "/supplychain", name: "menu.supplychain", icon: "local_shipping" };
export const scPointRoute = { path: `${supplyChainRoute.path}/point`, name: "menu.supplychain_point" };
export const scPointCreateRoute = { path: `${scPointRoute.path}/create`, name: "supplychain.add" };
export const scPointEditRoute = { path: `${scPointRoute.path}/edit`, name: "supplychain.edit" };
export const scGoodsReqRoute = { path: `${supplyChainRoute.path}/request`, name: "menu.supplychain_request" };
export const scGoodsReqCreateRoute = { path: `${scGoodsReqRoute.path}/create`, name: "supplychain.addgoodsreq" };
export const scGoodsReqEditRoute = { path: `${scGoodsReqRoute.path}/edit`, name: "supplychain.editgoodsreq" };
export const scGoodsReqViewRoute = { path: `${scGoodsReqRoute.path}/view`, name: "supplychain.detailgoodsreq" };

// component
const Supplychain = lazy(() => import("../page/supplychain/Supplychain"));
const ScItemRequest = lazy(() => import("../page/supplychain/item_request/ItemRequest"));
const ScItemRequestView = lazy(() => import("../page/supplychain/item_request/view/ItemRequestView"));
const ScItemRequestForm = lazy(() => import("../page/supplychain/item_request/form/ItemRequestForm"));
const ScDistributionPoint = lazy(() => import("../page/supplychain/distribution_point/DistributionPoint"));
const ScDistributionPointForm = lazy(() => import("../page/supplychain/distribution_point/DistributionPointForm"));

export const supplychainListRoutes = [scPointRoute, scGoodsReqRoute];
export const scMutationRoutes = [
    scPointCreateRoute, scPointEditRoute,
    scGoodsReqCreateRoute, scGoodsReqEditRoute, scGoodsReqViewRoute
];

export const supplyChainRoutes = {
    element: <Supplychain />,
    children: [
        { ...scPointRoute, element: <ScDistributionPoint /> },
        { ...scPointCreateRoute, element: <ScDistributionPointForm type="add" /> },
        { ...scPointEditRoute, element: <ScDistributionPointForm type="edit" /> },
        { ...scGoodsReqRoute, element: <ScItemRequest /> },
        { ...scGoodsReqCreateRoute, element: <ScItemRequestForm type="add" /> },
        { ...scGoodsReqEditRoute, element: <ScItemRequestForm type="edit" /> },
        { ...scGoodsReqViewRoute, element: <ScItemRequestView /> },
    ],
};
