import { lazy } from "react";

// route
export const reportRoute = { path: "/report", name: "menu.report", icon: "content_paste" };
export const rProductPriceValueRoute = { path: `${reportRoute.path}/productPriceValue`, name: "Nilai Harga Produk" };
export const rPurchaseAnalysisRoute = { path: `${reportRoute.path}/purchaseAnalysis`, name: "Analisis Pembelian" };
export const rSellingInvoiceRoute = { path: `${reportRoute.path}/inv_out`, name: "Penjualan" };
export const rSellingInvoiceDetailRoute = { path: `${reportRoute.path}/inv_out_detail`, name: "Detail Penjualan" };
export const rPurchInvoiceRoute = { path: `${reportRoute.path}/purchaseInvoice`, name: "Pembelian" };
export const rDORoute = { path: `${reportRoute.path}/do`, name: "Delivery Order" };
export const rDODetailRoute = { path: `${reportRoute.path}/do_detail`, name: "Detail Delivery Order" };
export const rSORoute = { path: `${reportRoute.path}/so`, name: "Sales Order" };
export const rSODetailRoute = { path: `${reportRoute.path}/so_detail`, name: "Detail Sales Order" };

// component
const Report = lazy(() => import("../page/report/Report"));
const RProductPriceValue = lazy(() => import("../page/report/product_price_value/ReportProductPriceValue"));
const RSellingInvoice = lazy(() => import("../page/report/selling_invoice/ReportSellingInvoice"));
const RSellingInvoiceDetail = lazy(() => import("../page/report/selling_invoice/ReportSellingInvoiceDetail"));
const RPurchaseInvoice = lazy(() => import("../page/report/purchase_invoice/ReportPurchaseInvoice"));
const RDO = lazy(() => import("../page/report/do/ReportDO"));
const RDODetail = lazy(() => import("../page/report/do/ReportDODetail"));
const RSO = lazy(() => import("../page/report/so/ReportSO"));
const RSODetail = lazy(() => import("../page/report/so/ReportSODetail"));

export const reportListRoutes = [
    // rProductPriceValueRoute,
    // rPurchaseAnalysisRoute,
    rSellingInvoiceRoute, rSellingInvoiceDetailRoute,
    rPurchInvoiceRoute,
    rDORoute, rDODetailRoute,
    rSORoute, rSODetailRoute,
];

export const reportRoutes = {
    element: <Report />,
    children: [
        { ...rProductPriceValueRoute, element: <RProductPriceValue /> },
        // { ...rPurchaseAnalysisRoute, element: <></> },
        { ...rSellingInvoiceRoute, element: <RSellingInvoice /> },
        { ...rSellingInvoiceDetailRoute, element: <RSellingInvoiceDetail /> },
        { ...rPurchInvoiceRoute, element: <RPurchaseInvoice /> },
        { ...rDORoute, element: <RDO /> },
        { ...rDODetailRoute, element: <RDODetail /> },
        { ...rSORoute, element: <RSO /> },
        { ...rSODetailRoute, element: <RSODetail /> },
    ],
};
