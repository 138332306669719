import { lazy } from "react";

// route
export const salesRoute = { path: "/sales", name: "menu.sales", icon: "point_of_sale" };
export const sQuotationRoute = { path: `${salesRoute.path}/quotation`, name: "Penawaran", code: 'QP' };
export const sQuoCreateRoute = { path: `${sQuotationRoute.path}/create`, name: "Buat Penawaran", code: 'QP' };

export const sSORoute = { path: `${salesRoute.path}/so`, name: "Sales Order", code: 'SO' };
export const sSOViewRoute = { path: `${sSORoute.path}/view`, name: "Detail Sales Order", code: 'SO' };
export const sSOCreateRoute = { path: `${sSORoute.path}/create`, name: "Buat Sales Order", code: 'SO' };
export const sSOUpdateRoute = { path: `${sSORoute.path}/edit`, name: "Ubah Sales Order", code: 'SO' };
export const sDOOutCreateRoute = { path: `${sSORoute.path}/do/create`, name: "Sales Order (DO OUT)", code: 'SO' };
export const sPORoute = { path: `${salesRoute.path}/po`, name: "Purchase Order Masuk", code: 'SO' };

export const sRefItemOutRoute = { path: `${salesRoute.path}/do`, name: "Delivery Order", code: 'DOOUT' };
export const sRefItemOutViewRoute = { path: `${sRefItemOutRoute.path}/view`, name: "Detail Delivery Order", code: 'DOOUT' };

export const sInvoiceRoute = { path: `${salesRoute.path}/invoice`, name: "Tagihan Penjualan", code: 'INVS' };
export const sInvoiceCreateRoute = { path: `${sInvoiceRoute.path}/create`, name: "Buat Tagihan Penjualan", code: 'INVS' };
export const sInvoiceUpdateRoute = { path: `${sInvoiceRoute.path}/edit`, name: "Ubah Tagihan Penjualan", code: 'INVS' };
export const sInvoiceViewRoute = { path: `${sInvoiceRoute.path}/view`, name: "Detail Tagihan Penjualan", code: 'INVS' };

export const sReturnCusRoute = { path: `${salesRoute.path}/returnCustomer`, name: "sales.returncustomer.", code: 'RCP' };

// component
const Sales = lazy(() => import("../page/sales/Sales"));
const SalesSO = lazy(() => import("../page/sales/so/SalesSO"));
const SalesSOView = lazy(() => import("../page/sales/so/view/SOView"));
const SalesSOForm = lazy(() => import("../page/sales/so/form/SalesSOForm"));
const SalesDOOutForm = lazy(() => import("../page/sales/so/form_do_out/DOOutForm"));
const SalesPO = lazy(() => import("../page/sales/po/SalesPO"));

const SalesRefItemOut = lazy(() => import("../page/sales/ref_item_out/SalesRefItemOut"));
const SalesRefItemOutView = lazy(() => import("../page/sales/ref_item_out/view/SalesRefItemOutView"));

const SalesQuotation = lazy(() => import("../page/sales/quotation/SalesQuotation"));
const SalesQuotationForm = lazy(() => import("../page/sales/quotation/SalesQuotationForm"));

const SalesInvoice = lazy(() => import("../page/sales/invoice/SalesInvoice"));
const SalesInvoiceForm = lazy(() => import("../page/sales/invoice/form/SalesInvoiceForm"));
const SalesInvoiceView = lazy(() => import("../page/sales/invoice/view/SalesInvoiceView"));

const SalesReturCus = lazy(() => import("../page/sales/return_customer/SalesReturnCustomer"));

export const salesMainTabRoutes = [
    sQuotationRoute, sSORoute,
    sRefItemOutRoute, sInvoiceRoute, sReturnCusRoute
];

export const salesMutationRoutes = [
    sQuoCreateRoute,
    sSOCreateRoute, sSOUpdateRoute, sSOViewRoute, sDOOutCreateRoute, sPORoute,
    sRefItemOutViewRoute,
    sInvoiceCreateRoute, sInvoiceUpdateRoute, sInvoiceViewRoute
];

export const salesRoutes = {
    element: <Sales />,
    children: [
        { ...sQuotationRoute, element: <SalesQuotation /> },
        { ...sQuoCreateRoute, element: <SalesQuotationForm /> },
        { ...sPORoute, element: <SalesPO /> },
        { ...sSORoute, element: <SalesSO /> },
        { ...sSOCreateRoute, element: <SalesSOForm /> },
        { ...sSOUpdateRoute, element: <SalesSOForm /> },
        { ...sSOViewRoute, element: <SalesSOView /> },
        { ...sDOOutCreateRoute, element: <SalesDOOutForm /> },
        { ...sRefItemOutRoute, element: <SalesRefItemOut /> },
        { ...sRefItemOutViewRoute, element: <SalesRefItemOutView /> },
        { ...sInvoiceRoute, element: <SalesInvoice /> },
        { ...sInvoiceCreateRoute, element: <SalesInvoiceForm /> },
        { ...sInvoiceUpdateRoute, element: <SalesInvoiceForm /> },
        { ...sInvoiceViewRoute, element: <SalesInvoiceView /> },
        { ...sReturnCusRoute, element: <SalesReturCus /> },
    ],
};