import { createTheme } from "@mui/material";

const cssVar = (name) => getComputedStyle(document.querySelector(":root")).getPropertyValue(name).trim();
const THEME = createTheme({
    palette: {
        primary: { main: cssVar('--primary_color') }
    },
    typography: {
        fontFamily: 'Poppins',
        allVariants: { lineHeight: '1.2' },
        body1: { textTransform: 'capitalize' }
    },
    components: {
        MuiButtonBase: { defaultProps: { disableRipple: true } },
    }
})

export default THEME