import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import { storage } from "../Storage"
import main_en_language from "./en"
import main_id_language from "./id"

const initLanguage = storage.getItem("LNG")?.state?.lng ?? "id"
i18next.use(initReactI18next).init({
	resources: { en: main_en_language, id: main_id_language },
	fallbackLng: ["id", "en"],
	lng: initLanguage,
	interpolation: { escapeValue: false },
})

export default i18next
