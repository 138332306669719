import { lazy } from "react";

// route
export const mitraSCMRoute = { path: "/mitra", name: "Mitra SCM Online", icon: "people" };
export const mitraSearchRoute = { path: `${mitraSCMRoute.path}/search`, name: "Cari Mitra" };
export const mitraConfirmationRoute = { path: `${mitraSCMRoute.path}/confirmation`, name: "Konfirmasi Permintaan Mitra" };
export const mitraListRoute = { path: `${mitraSCMRoute.path}/list`, name: "Daftar Mitra" };
export const mitraStockReqRoute = { path: `${mitraSCMRoute.path}/stock_request`, name: "Permintaan Stok" };
export const mitraAddStckReqRoute = { path: `${mitraStockReqRoute.path}/add`, name: "Tambah Permintaan Stok" };
export const mitraEditStckReqRoute = { path: `${mitraStockReqRoute.path}/edit`, name: "Ubah Permintaan Stok" };
export const mitraViewtStckReqRoute = { path: `${mitraStockReqRoute.path}/view`, name: "Detail Permintaan Stok" };

// component
const MitraSCM = lazy(() => import("../page/mitra/MitraSCM"));
const MitraSCM2 = lazy(() => import("../page/mitra/MitraSCM2"));
const MitraSearch = lazy(() => import("../page/mitra/search/MitraSearch"));
const MitraConfirmation = lazy(() => import("../page/mitra/confirmation/MitraConfirmation"));
const MitraList = lazy(() => import("../page/mitra/list/MitraList"));
const MitraStockRequest = lazy(() => import("../page/mitra/stock_request/MitraStockRequest"));
const MitraFromStockRequest = lazy(() => import("../page/mitra/stock_request/MitraFromStockReq"));
const MitraViewStockRequest = lazy(() => import("../page/mitra/stock_request/MitraViewStockReq"));

export const mitraTabRoute = [mitraSearchRoute, mitraConfirmationRoute];
export const mitraListRoutes = [mitraSearchRoute, mitraListRoute, mitraStockReqRoute];
export const mitraMutationRutes = [mitraListRoute, mitraStockReqRoute, mitraAddStckReqRoute]

export const mitarSCMRoutes = [
    {
        element: <MitraSCM />,
        children: [
            { ...mitraSearchRoute, element: <MitraSearch /> },
            { ...mitraConfirmationRoute, element: <MitraConfirmation /> },
        ],
    },
    {
        element: <MitraSCM2 />,
        children: [
            { ...mitraListRoute, element: <MitraList /> },
            { ...mitraStockReqRoute, element: <MitraStockRequest /> },
            { ...mitraAddStckReqRoute, element: <MitraFromStockRequest type="add" /> },
            { ...mitraEditStckReqRoute, element: <MitraFromStockRequest type="edit" /> },
            { ...mitraViewtStckReqRoute, element: <MitraViewStockRequest /> },
        ],
    }
];