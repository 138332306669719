import { lazy } from "react";

// routes
export const transRoute = { path: "/transaction", name: "menu.whstrans", icon: "receipt_long" };
export const transItemInRoute = { path: `${transRoute.path}/itemIn`, name: "transaction.itemin." };
export const transItemOutRoute = { path: `${transRoute.path}/itemOut`, name: "transaction.itemout." };
export const convertionRoute = { path: `${transRoute.path}/convertion`, name: "transaction.convertion." };

export const stockOpnameRoute = { path: `${transRoute.path}/stockOpname`, name: "transaction.stockopname." };
export const stockOpnameCreateRoute = { path: `${stockOpnameRoute.path}/create`, name: "transaction.stockopname.add" };
export const stockOpnameUpdateRoute = { path: `${stockOpnameRoute.path}/edit`, name: "transaction.stockopname.edit" };
export const stockOpnameViewRoute = { path: `${stockOpnameRoute.path}/detail`, name: "transaction.stockopname.detail" };

export const transItemMoveRoute = { path: `${transRoute.path}/itemMove`, name: "transaction.itemmove." };
export const productionRoute = { path: `${transRoute.path}/production`, name: "transaction.itemprocess." };
export const productionCreateRoute = { path: `${productionRoute.path}/create`, name: "buat proses barang" };
export const productionEditRoute = { path: `${productionRoute.path}/edit`, name: "ubah proses barang" };
export const productionViewRoute = { path: `${productionRoute.path}/view`, name: "detail proses barang" };

export const initStockRoute = { path: `${transRoute.path}/initStock`, name: "transaction.initstock." };

export const itemInListRoute = { path: `${transRoute.path}/itemInList`, name: "transaction.iteminlist." };
export const itemInListViewRoute = { path: `${itemInListRoute.path}/view`, name: "transaction.iteminlist.detail" };

export const itemOutListRoute = { path: `${transRoute.path}/itemOutList`, name: "transaction.itemoutlist." };
export const itemOutListViewRoute = { path: `${itemOutListRoute.path}/view`, name: "transaction.itemoutlist.detail" };

// component
const Transaction = lazy(() => import("../page/transaction/Transaction"));
const TransItemIn = lazy(() => import("../page/transaction/item_in/TransItemIn"));
const TransItemOut = lazy(() => import("../page/transaction/item_out/TransItemOut"));
const TransConversion = lazy(() => import("../page/transaction/conversion/TransConversion"));
const TransStockOpname = lazy(() => import("../page/transaction/stock_opname/TransStockOpname"));
const TransStockOpnameFrom = lazy(() => import("../page/transaction/stock_opname/form/TransStockOpnameForm"));
const TransStockOpnameView = lazy(() => import("../page/transaction/stock_opname/view/TransStockOpnameView"));
const TransItemMove = lazy(() => import("../page/transaction/item_move/TransItemMove"));
const TransProduction = lazy(() => import("../page/transaction/production/TransProduction"));
const TransProductionForm = lazy(() => import("../page/transaction/production/form/TransProductionForm"));
const ItemInList = lazy(() => import("../page/transaction/list_item_in/TransItemInList"));
const ItemInListView = lazy(() => import("../page/transaction/list_item_in/view/TransItemInView"));
const ItemOutList = lazy(() => import("../page/transaction/list_item_out/TransItemOutList"));
const ItemOutListView = lazy(() => import("../page/transaction/list_item_out/view/TransItemOutView"));
const InitStock = lazy(() => import("../page/transaction/init_stock/InitStock"));

export const transListRoutes = [
    transItemInRoute,
    transItemOutRoute,
    convertionRoute,
    stockOpnameRoute,
    transItemMoveRoute,
    productionRoute,
    initStockRoute,
    itemInListRoute,
    itemOutListRoute,
];

export const transMutationRoutes = [
    itemInListViewRoute, itemOutListViewRoute,
    stockOpnameCreateRoute, stockOpnameUpdateRoute,
    productionCreateRoute, productionEditRoute
];

export const transactionRoutes = {
    element: <Transaction />,
    children: [
        { ...transItemInRoute, element: <TransItemIn /> },
        { ...transItemOutRoute, element: <TransItemOut /> },
        { ...convertionRoute, element: <TransConversion /> },
        { ...stockOpnameRoute, element: <TransStockOpname /> },
        { ...stockOpnameCreateRoute, element: <TransStockOpnameFrom type='add' /> },
        { ...stockOpnameUpdateRoute, element: <TransStockOpnameFrom type='edit' /> },
        { ...stockOpnameViewRoute, element: <TransStockOpnameView /> },
        { ...transItemMoveRoute, element: <TransItemMove /> },
        { ...productionRoute, element: <TransProduction /> },
        { ...productionCreateRoute, element: <TransProductionForm /> },
        { ...productionEditRoute, element: <TransProductionForm /> },
        { ...itemInListRoute, element: <ItemInList /> },
        { ...itemInListViewRoute, element: <ItemInListView /> },
        { ...itemOutListRoute, element: <ItemOutList /> },
        { ...itemOutListViewRoute, element: <ItemOutListView /> },
        { ...initStockRoute, element: <InitStock /> },
    ],
};