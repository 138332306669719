const en_master_goods = {
	add: "add goods",
	edit: "edit goods",
	price: "price goods",
	upload: "upload goods",
	barcode: "print barcode",
	view: "goods detail",
	access_chasier: "cashier access",
	access_request: "request access",
}

export default en_master_goods
