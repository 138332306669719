import { EncryptStorage } from "encrypt-storage";

export const storage = new EncryptStorage("global_params", {
    storageType: 'localStorage',
    encAlgorithm: 'Rabbit'
});

export const _sessionStorage = new EncryptStorage("global_session_params", {
    storageType: 'sessionStorage',
    encAlgorithm: 'Rabbit'
});