import { lazy } from "react";

// route
export const assetMgmtRoute = { path: "/asset", name: "Management Aset", icon: "hourglass_top" };
export const amAddRoute = { path: `${assetMgmtRoute.path}/create`, name: "Tambah Aset" };
export const amListRoute = { path: `${assetMgmtRoute.path}/list`, name: "Daftar Aset" };
export const amHistoryRoute = { path: `${assetMgmtRoute.path}/history`, name: "Riwayat Aset" };

// component
const AssetManagement = lazy(() => import("../page/asset_management/AssetManagement"));

export const assetMgmtListRoutes = [amAddRoute, amListRoute, amHistoryRoute];

export const assetMgmtRoutes = {
    element: <AssetManagement />,
    children: [
        { ...amAddRoute, element: <></> },
        { ...amListRoute, element: <></> },
        { ...amHistoryRoute, element: <></> },
    ],
};

