import { lazy } from "react";

// route
export const ecommRoute = { path: "/ecommerce", name: "menu.ecommerce", icon: "shopping_cart" };
export const ecommRegisterRoute = { path: `${ecommRoute.path}/register`, name: "menu.ecommregister" };
export const ecommListRoute = { path: `${ecommRoute.path}/list`, name: "menu.ecommlist" };

// component
const Ecommerce = lazy(() => import("../page/ecommerce/Ecommerce"));
const EcommerceRegister = lazy(() => import("../page/ecommerce/register/EcommerceRegister"));
const EcommerceList = lazy(() => import("../page/ecommerce/list/EcommerceList"));

export const ecommmerceListRoutes = [ecommRegisterRoute, ecommListRoute];
export const ecommerceRoutes = {
    element: <Ecommerce />,
    children: [
        { ...ecommRegisterRoute, element: <EcommerceRegister /> },
        { ...ecommListRoute, element: <EcommerceList /> },
    ],
};
