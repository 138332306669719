import { lazy } from "react";

//route
export const purchaseRoute = { path: "/purchase", name: "menu.purchase", icon: "shopping_bag" };
export const purchPORoute = { path: `${purchaseRoute.path}/po`, name: "Purchase Order Keluar", code: 'PO' };
export const purchPOViewRoute = { path: `${purchPORoute.path}/view`, name: "Detail Purchase Order", code: 'PO' };
export const purchPOCreateRoute = { path: `${purchPORoute.path}/create`, name: "Buat Purchase Order Keluar", code: 'PO' };
export const purchPOEditRoute = { path: `${purchPORoute.path}/edit`, name: "Ubah Purchase Order Keluar", code: 'PO' };
export const purchDOInCreateRoute = { path: `${purchPORoute.path}/do/create`, name: "Barang Masuk (DO)", code: 'PO' };

export const purchPOMitraCreateRoute = { path: `${purchPORoute.path}/create`, name: "Buat Purchase Order Keluar Mitra", code: 'PO' };
export const purchPOMitraEditRoute = { path: `${purchPORoute.path}/edit`, name: "Ubah Purchase Order Keluar Mitra", code: 'PO' };

export const purchQuotationRoute = { path: `${purchaseRoute.path}/quotation`, name: "Permintaan Penawaran", code: 'QP' };
export const purchQuotationCreateRoute = { path: `${purchQuotationRoute.path}/create`, name: "Buat Permintaan Penawaran", code: 'QP' };

export const purchInvoiceRoute = { path: `${purchaseRoute.path}/invoice`, name: "Tagihan Pembelian", code: 'INVP' };
export const purchInvoiceCreateRoute = { path: `${purchInvoiceRoute.path}/create`, name: "Buat Tagihan Pembelian", code: 'INVP' };
export const purchInvoiceEditRoute = { path: `${purchInvoiceRoute.path}/edit`, name: "Ubah Tagihan Pembelian", code: 'INVP' };

export const purchItemInRoute = { path: `${purchaseRoute.path}/doin`, name: "Barang Masuk", code: 'DOIN' };
export const purchItemInViewRoute = { path: `${purchItemInRoute.path}/view`, name: "Detail Barang Masuk", code: 'DOIN' };

export const purchReturnSupRoute = { path: `${purchaseRoute.path}/returnSupplier`, name: "purch.returnsupplier.", code: 'RSP' };

// component
const Purchase = lazy(() => import("../page/purchase/Purchase"));
const PurchPO = lazy(() => import("../page/purchase/po/PO"));
const PurchPOView = lazy(() => import("../page/purchase/po/view/POView"));
const PurchPOFrom = lazy(() => import("../page/purchase/po/form/POForm"));
const PurchDOInForm = lazy(() => import("../page/purchase/po/form_do_in/DOInForm"));
const PurchPOMitraFrom = lazy(() => import("../page/purchase/po/form/POMitraForm"));

const PurchRefItemIn = lazy(() => import("../page/purchase/ref_item_in/PurchaseRefItemIn"));
const PurchRefItemInView = lazy(() => import("../page/purchase/ref_item_in/view/PurchaseRefItemInView"));

const PurchQuotation = lazy(() => import("../page/purchase/quotation/PurchaseQuotation"));
const PurchQuotationForm = lazy(() => import("../page/purchase/quotation/PurchaseQuotationForm"));

const PurchInvoice = lazy(() => import("../page/purchase/invoice/PurchaseInvoice"));
const PurchInvoiceForm = lazy(() => import("../page/purchase/invoice/form/PurchaseInvoiceForm"));

const PurchReturSup = lazy(() => import("../page/purchase/return_supplier/PurchReturnSupplier"));

export const purchMainTabRoutes = [
    purchQuotationRoute,
    purchPORoute,
    purchInvoiceRoute,
    purchItemInRoute,
    purchReturnSupRoute,
];
export const purchaseMutationRoutes = [
    purchQuotationCreateRoute,
    purchInvoiceCreateRoute, purchInvoiceEditRoute,
    purchPOViewRoute, purchPOCreateRoute, purchPOEditRoute, purchDOInCreateRoute,
    purchPOMitraCreateRoute, purchPOMitraEditRoute,
    purchItemInViewRoute
];

export const purchaseRoutes = {
    element: <Purchase />,
    children: [
        { ...purchQuotationRoute, element: <PurchQuotation /> },
        { ...purchQuotationCreateRoute, element: <PurchQuotationForm type='create' /> },
        { ...purchPORoute, element: <PurchPO /> },
        { ...purchPOViewRoute, element: <PurchPOView /> },
        { ...purchPOCreateRoute, element: <PurchPOFrom type='create' /> },
        { ...purchPOEditRoute, element: <PurchPOFrom type='edit' /> },
        { ...purchPOMitraCreateRoute, element: <PurchPOMitraFrom type='create' /> },
        { ...purchPOMitraEditRoute, element: <PurchPOMitraFrom type='edit' /> },
        { ...purchInvoiceRoute, element: <PurchInvoice /> },
        { ...purchInvoiceCreateRoute, element: <PurchInvoiceForm /> },
        { ...purchInvoiceEditRoute, element: <PurchInvoiceForm /> },
        { ...purchItemInRoute, element: <PurchRefItemIn /> },
        { ...purchItemInViewRoute, element: <PurchRefItemInView /> },
        { ...purchReturnSupRoute, element: <PurchReturSup /> },
        { ...purchDOInCreateRoute, element: <PurchDOInForm /> }
    ],
};