import { lazy } from "react";

// route
export const rkaRoute = { path: "/rka", name: "menu.rka", icon: "balance" };
export const rkaPlanningRoute = { path: `${rkaRoute.path}/bankAccount`, name: "rka.planning." };
export const rkaPlanningCreateRoute = { path: `${rkaPlanningRoute.path}/create`, name: "rka.planning.add" };
export const rkaPlanningEditRoute = { path: `${rkaPlanningRoute.path}/edit`, name: "rka.planning.edit" };

// component
const RKA = lazy(() => import("../page/rka/RKA"));
const RKAPlanning = lazy(() => import("../page/rka/planning/RKAPlanning"));
const RKAPlanningForm = lazy(() => import("../page/rka/planning/form/RKAPlanningForm"));

export const RKAListRoutes = [
    rkaPlanningRoute
]

export const RKAMutationRoute = [
    rkaPlanningRoute, rkaPlanningCreateRoute, rkaPlanningEditRoute
]

export const rkaRoutes = {
    element: <RKA />,
    children: [
        { ...rkaPlanningRoute, element: <RKAPlanning /> },
        { ...rkaPlanningCreateRoute, element: <RKAPlanningForm /> },
        { ...rkaPlanningEditRoute, element: <RKAPlanningForm /> },
    ],
};